if ($) {
  $(document).ajaxComplete(function () {
    addtargetblank();
  });

  if ($("body").hasClass("ProcessPageEdit")) {
    $("#_ProcessPageEditView").attr("target", "_blank");
  }
  $(".pw-dropdown-menu li:first-child a").attr("target", "_blank");

  function addtargetblank() {
    $("li.PageListActionView a").each(function () {
      if ($(this).attr("target") == undefined) {
        $(this).attr("target", "_blank");
      }
    });

    if ($("#_ProcessPageEditView").attr("target") == undefined) {
      $("#_ProcessPageEditView").attr("target", "_blank");
    }

    document.querySelectorAll("a").forEach((el) => {
      if (!el.href.includes("cms")) {
        el.setAttribute("target", "_blank");
      }
    });
  }
}
