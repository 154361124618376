import "keen-slider/keen-slider.min.css";
import KeenSlider from "keen-slider";

const sliderElement = document.querySelector("#accommodation-slider");

if (sliderElement && sliderElement.childElementCount > 1) {
  var interval = 0;
  function autoplay(run) {
    clearInterval(interval);
    interval = setInterval(() => {
      if (run && slider) {
        slider.next();
      }
    }, 2500);
  }

  function updateClasses(instance) {
    var slide = instance.details().relativeSlide;
    var arrowLeft = sliderElement.querySelector("#arrow-left");
    var arrowRight = sliderElement.querySelector("#arrow-right");
    slide === 0
      ? arrowLeft.classList.add("arrow--disabled")
      : arrowLeft.classList.remove("arrow--disabled");
    slide === instance.details().size - 1
      ? arrowRight.classList.add("arrow--disabled")
      : arrowRight.classList.remove("arrow--disabled");
    var slides = sliderElement.querySelectorAll(".keen-slider__slide");
    slides.forEach((el, i) => {
      el.classList.add("slide--faded");
    });
    sliderElement
      .querySelectorAll(".keen-slider__slide")
      [slide].classList.remove("slide--faded");
  }

  var slider = new KeenSlider(sliderElement, {
    centered: true,
    slidesPerView: 1,
    loop: true,
    duration: 1000,
    dragStart: () => {
      autoplay(false);
    },
    dragEnd: () => {
      autoplay(true);
    },
    created: function (instance) {
      sliderElement
        .querySelector("#arrow-left")
        .addEventListener("click", function () {
          instance.prev();
        });

      sliderElement
        .querySelector("#arrow-right")
        .addEventListener("click", function () {
          instance.next();
        });
      updateClasses(instance);
    },
    slideChanged(instance) {
      updateClasses(instance);
    },
  });

  sliderElement.addEventListener("mouseover", () => {
    autoplay(false);
  });
  sliderElement.addEventListener("mouseout", () => {
    autoplay(true);
  });
  autoplay(true);
}
